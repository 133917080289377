.App {
  text-align: center;
  padding-top: 300px;
}

button{
  font-size: 3rem;
}

p{
  font-size: 1.5rem;
  font-weight: bold;
}
